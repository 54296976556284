import React from "react";
import { AiFillDatabase, AiOutlineRobot, AiOutlineCheck } from "react-icons/ai";
import { BsHddNetwork } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";
import { GiSatelliteCommunication } from "react-icons/gi";
import { IoMdAnalytics } from "react-icons/io";
import { FaChalkboardTeacher } from "react-icons/fa";
import { Link } from "gatsby";

const Solutions = () => {
  return (
    <div id="solutions" className="w-full px-6 py-24 m-auto max-w-7xl md:px-10 md:py-12">
      <div className="flex flex-col items-center justify-center w-full mb-20">
        <h1 className="lg:mt-0 montserrat mt-12 md:text-4xl text-2xl font-bold md:mb-6 text-center text-[#4A4B4B] mb-12 uppercase">
          SOLUTIONS
        </h1>
        <p className="text-center text-[#4A4B4B] pb-12 max-w-[600px]">
          We offer over a thousand solutions from hundreds of providers. The
          best way to assess your needs is through a solutions discussion with
          one of our specialists.
        </p>
        <div className="flex flex-wrap justify-center gap-5">
          {cards.map(({ id, title, icon, list, iconClr }) => {
            return (
              <div
                key={id}
                className="flex flex-col p-10 border solutionn_card  items-center rounded-2xl max-w-[270px] w-full"
              >
                <div
                  className={`${iconClr} text-3xl text-white solutionn_icon flex justify-center items-center shadow-xl mb-5 w-14 h-14  rounded-full`}
                >
                  {icon}
                </div>
                <h3 className="mb-10 text-xl font-bold text-gray-900">
                  {title}
                </h3>

                <ul className="flex flex-col gap-1">
                  {list.map(({ id, li }) => {
                    return (
                      <li className="flex items-center gap-2 pb-2 text-sm" key={id}>
                        <span>
                          <AiOutlineCheck className="text-base font-semibold text-purple-primary" />
                        </span>
                        {li}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-center">
        <Link to="https://calendly.com/dealersys/solutions-discussion">
          <span className="font-medium text-sm bg-black text-white px-4 py-3.5 rounded-lg transition-all hover:shadow-lg">
            Help me choose the right solution
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Solutions;

const cards = [
  {
    id: 1,
    title: "Data",
    icon: <AiFillDatabase />,
    iconClr: "colorA",
    list: [
      { id: 1, li: "Primary & 2ndary Internet Circuits" },
      { id: 2, li: "Fiber" },
      { id: 3, li: "Coax" },
      { id: 4, li: "DSL" },
      { id: 5, li: "Fixed Wireless" },
      { id: 6, li: "EOC" },
      { id: 7, li: "4G / 5G" },
      { id: 8, li: "Data Transport" },
      { id: 9, li: "MPLS" },
    ],
  },
  {
    id: 2,
    title: "Networking",
    icon: <BsHddNetwork />,
    iconClr: "colorB",
    list: [
      { id: 1, li: "Wi-Fi" },
      { id: 2, li: "Switches and Cabling" },
      { id: 3, li: "MPLS" },
      { id: 4, li: "SD WAN" },
    ],
  },
  {
    id: 3,
    title: "Security",
    icon: <MdOutlineSecurity />,
    iconClr: "colorC",
    list: [
      { id: 1, li: "Firewall" },
      { id: 2, li: "SD WAN" },
      { id: 3, li: "DDOS" },
      { id: 4, li: "Two-Factor Authentication" },
    ],
  },
  {
    id: 4,
    title: "Communications",
    icon: <GiSatelliteCommunication />,
    iconClr: "colorD",
    list: [
      { id: 1, li: "UCaaS (Hosted Phone System)" },
      { id: 2, li: "PBX (Traditional Phone System)" },
      { id: 3, li: "SIP / PRI / Analog Lines" },
      { id: 4, li: "Texting and Chat Platforms" },
      { id: 5, li: "Call Center and Omnichannel Platforms" },
    ],
  },
  {
    id: 5,
    title: "Analytics and Reporting",
    icon: <IoMdAnalytics />,
    iconClr: "colorE",
    list: [
      { id: 1, li: "Call and Text Tracking" },
      { id: 2, li: "Customizable Dashboards" },
      { id: 3, li: "System Integration" },
    ],
  },
  {
    id: 6,
    title: "AI",
    icon: <AiOutlineRobot />,
    iconClr: "colorF",
    list: [
      { id: 1, li: "Virtual Assistant" },
      { id: 2, li: "Opportunity Mining" },
      { id: 3, li: "Response" },
      { id: 4, li: "Appointment Setting" },
    ],
  },
  {
    id: 7,
    title: "Operational Support",
    icon: <FaChalkboardTeacher />,
    iconClr: "colorG",
    list: [
      { id: 1, li: "CRM & Process Training" },
      { id: 2, li: "Third-Party IT Management" },
      { id: 3, li: "Systems Integrations" },
    ],
  },
];
