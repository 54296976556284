import React from "react";
import Solutions from "./Solutions";
import Speak from "./../../components/Speak";

function Index({location}) {
  return (
    <div>
      <Solutions />
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
